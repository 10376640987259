.Navbar {

  a {
    color: color(light);
  }
  background-color: color(primary);
  // position: fixed;
  // top: 0;
  // right: 0;
  // left: 0;
  // z-index: 3;
  // background: white;
  // & + * {
  //   padding-top: 70px;
  // }
  // ul {
  //   display: flex;
  //   list-style: none;
  //   padding: 0;
  //   margin: auto;
  //   justify-content: space-around;
  //   height: 70px;
  //   align-items: center;
  // }
  // li {
  //   position: relative;
  //
  //   &.active {
  //     a {
  //       color: color('primary');
  //     }
  //   }
  // }
  // a {
  //   color: color(gray-dark);
  //   text-decoration: none;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   i {
  //     font-size: 26px;
  //   }
  //   span {
  //     margin-left: space(1);
  //   }
  // }
  // .Badge {
  //   position: absolute;
  //   left: 15px;
  //   bottom: 0px;
  //   font-size: 12px;
  //   height: 16px;
  //   min-width: 16px;
  //   padding: 0;
  //   display: flex;
  //   pointer-events: none;
  // }
  //
  // @include media-breakpoint(lg) {
  //   ul {
  //     justify-content: flex-start;
  //     li {
  //       margin-right: space(5);
  //     }
  //   }
  // }
}
