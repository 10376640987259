@import "blackflag/scss";
@import "./Navbar";

.Container {
  padding-left: space(2);
  padding-right: space(2);
}

.Button {
  border-radius: 0;
  background: transparent;
  font-weight: normal;
  padding-left: space(4);
  padding-right: space(4);
  border-width: 3px;
  fill: color(dark);

  svg, svg path {
    width: 32px;
    height: 32px;
  }

  &:hover {
    fill: color(light);
  }

  @include media-breakpoint-max(md) {
    padding-left: space(2);
    padding-right: space(2);
  }
}

a {
  &:hover {
    text-decoration: underline;
  }
}
