$delay-animation: 1s;
.page-login {
  background: url('../../assets/images/bg.png');
  background-size: cover;
  background-position: center;
  background-color: color(dark);
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "loading...";
    display: flex;
    align-items: center;
    justify-content: center;
    color: color(light);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: color(dark);
    z-index: 10;
    transition: .6s;
    transition-delay: $delay-animation;
  }
  &.loaded {
    &:before {
      opacity: 0;
      pointer-events: none;
    }
    .twerk {
      opacity: 1;
      transform: translateY(0);
    }
    .logo {
      opacity: 1;
      transform: translateY(0);
    }
    .Wrapper {
      .pt-2 {
        opacity: 1;
      }
      div p {
        opacity: 1;
      }
      a {
        opacity: 1;
      }
    }
  }

  .twerk {
    transition: 1s;
    transition-delay: $delay-animation + .3;
    transform: translateY(50%);
    max-width: 200px;
    opacity: 0;
  }
  .logo {
    transition: 1s;
    transition-delay: $delay-animation + .6;
    transform: translateY(50%);
    max-width: 528px;
    opacity: 0;
  }

  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: -50px;

    &:before {
      content: '';
      background: color(light);
      position: absolute;
      top: 0;
      bottom: 0;
      filter: blur(30px);
      opacity: 0.4;
      width: 100%;
      max-width: 528px;
    }

    div p {
      font-size: 14px;
      text-align: center;
    }

    &>* {
      position: relative;
      z-index: 2;
    }

    & > div {
      display: flex;
      justify-content: center;
    }

    .pt-2 {
      opacity: 0;
      transition: 1s;
      transition-delay: $delay-animation + 1.2;
    }

    div p {
      opacity: 0;
      transition: 1s;
      transition-delay: $delay-animation + 1.5;
    }
    a {
      opacity: 0;
      transition: 1s;
      transition-delay: $delay-animation + 1.9;
    }
  }

  footer {
    font-family: 'Roboto', 'Arial', 'Serif-Sans';
    background-color: color(dark);
    color: color(light);
    font-size: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: space(1);

    a {
      color: color(light);
      margin: 0 4px;
    }
  }
}
